import React from 'react';
import { BigTitle } from '../components/BigTitle';
import { CenteredDiv } from '../components/Layout/CenteredDiv';
import Footer from '../components/Footer';
import Logo from '../components/Logo';
import { Text } from '../components/Text';
import { Helmet } from 'react-helmet';

export default function contact() {
	return (
		<React.Fragment>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Thank You!</title>
			</Helmet>
			<CenteredDiv style={{ height: '80vh' }}>
				<Logo />
				<BigTitle style={{ marginTop: '4rem', maxWidth: '40rem' }}>
					Thanks!
				</BigTitle>
				<Text>We'll let you know when we're launched!</Text>
			</CenteredDiv>
			<Footer />
		</React.Fragment>
	);
}
